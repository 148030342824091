.modal-search-results{
    position: fixed;
    top: 10%;
    left: 0;
    right: 0;
    margin:0 auto;
    background-color: black;
    border-radius: 25px;
    width: 70%;
    min-height: 50%;
    z-index: 9;
}
.modal-search-results .modal-search-result-header{
    display: flex;
    width: 100%;
    min-height: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 47px 53px;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}
.modal-search-results .modal-search-result-header .cta-holder{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
}
.modal-search-results .modal-search-result-header .cta-holder button{
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    border: 0 none;
}
.modal-search-results .modal-search-result-header .title-holder h2{
    color: white;
    font-size: 24px;
    line-height: 56px;
    font-weight: 600;
}
.modal-search-result-body{
    padding: 57px 91px;
}
.modal-search-result-body .search-results{
    width: 100%;
    border: 0 none;
    color: white;
}
.modal-search-result-body .search-results thead tr td{
    padding: 21px 0;
    text-align: center;
}
/*.modal-search-result-body {
    max-height: 350px;
}*/
.modal-search-result-body .search-results tbody tr td{
    padding: 18px 0;
    text-align: center;
}
.modal-search-result-body .search-results tbody tr td:first-child, .modal-search-result-body .search-results thead tr td:first-child{
    text-align: left;
    min-width: 40%;
}
.results-modal-holder{
    max-height: 300px;
    overflow-y: scroll;
}
.no-resultados{
    display: block;
    text-align: center;
    color: #fff;
}
@media (max-width:768px){
    .modal-search-result-body {
        padding: 20px 18px 50px;
    }
    .modal-search-result-body .search-results  thead{
        display: none;
    }
    .modal-search-result-body .search-results tbody tr{
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
    }
    .modal-search-result-body .search-results tbody tr td{
        width: 33%;
        padding: 4px 0 18px;
        font-size: 16px;
        color: #efefef;
    }
    .modal-search-result-body .search-results tbody tr td:first-child{
        width: 100%;
        padding: 18px 0 0;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .modal-search-results{
        width: 84%;
    }
    .modal-search-results .modal-search-result-header{
        padding: 12px 18px;
    }
    .modal-search-results .modal-search-result-header .title-holder h2{
        font-size: 18px;
    }
   
}