.schelude-row{
    display: grid;
    grid-template-columns: 10% auto;
    color:#fff;
    height: 75px;
    align-items: center;
    margin-bottom: 4px ;
    padding-top: 10px;
}
.schelude-container-ul{
    overflow: hidden;
}
.canal-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.canal-card .canal-logo img{
    max-height: 75px;
    filter: grayscale(100%);
    transition: filter 0.2s ease 0s;
}
.schelude-row:hover .canal-card .canal-logo img{
    filter: none;
}
.schelude-result{
    width: 8640px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    gap: 4px;
    position: relative;
    height: 75px;
    transition: left 0.3s ease 0s;
}
.schelude-result li{
    display: flex;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .2);
    padding: 15px auto;
    align-items: center;
    justify-content: center;
    height: 75px;
    box-sizing: border-box;
    transition: all 0.2s ease 0s;
}
.schelude-row .schelude-result li:hover{
    background-color: rgba(255, 255, 255, .4) !important;
}
.schelude-result li > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.schelude-result li > div p{
    margin: 0;
    font-size: 14px;
    
}
.schelude-result li > div p{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}

.schelude-result li > div div.caption-schelude{
    overflow: hidden;
    width: 100%;
    padding: 15px 10px;
    position:relative;
    z-index: 1;
}
.schelude-container-ul{
    position: relative;
}
.programacion-canales--container{
    position:relative;
    max-height: 717px;
    overflow-y: scroll;
}
.progress-bar{
    display: block;
    position: absolute;
    height: 100%;
    z-index: 0;
    background-color: #4D0D28;
    left: 0;
    top:0;
    border-radius: 10px 0 0 10px;
}
.clavija-tiempo{
    display: block;
    position: absolute;
    width: 5px;
    height: 105%;
    background-color: #FF2C83;
    box-shadow: 0px 0px 8px #000000;
    z-index: 2;
    left: -5px;
    bottom: 2%;
}
@media (max-width:768px){
    .schelude-row{
        grid-template-columns: 22% auto;
        height: 90px;
        margin-bottom: 0;
    }
    .schelude-result{
        height: 90px;
    }
    .canal-card{
        flex-direction: column-reverse;
        border: 1px solid white;
        font-size: 12px;
        height: 90px;
        justify-content: space-around;
        padding: 10px 0;
    }
    .canal-card .canal-logo{
        position: relative;
        height: 48px;
    }
    .canal-card .canal-logo img{
        max-height: 100%;
    }
    .schelude-result li{
        height: 90px;
        border: 1px solid white;
        background-color: #000000;
        box-sizing: border-box;
        border-radius: 0;
        position: absolute;
        top: 0;
        box-sizing: border-box;
        width: 179px;
    }
    .canal-card .canal-logo img{
        filter: none;
        height: 48px;
    }
    .caption-schelude .hour-end{
        display: none;
    }
}