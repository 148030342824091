.headerGrilla{
    display: flex;
    flex-direction: column;
    
}
.filters-clack{
    display: grid;
    grid-template-columns: 192px 5fr auto;
    padding-left: 10%;
    padding-top: 24px;
    padding-bottom: 50px;
    padding-right: 20px;
    gap: 2rem ;
    width: 100%;
    box-sizing: border-box;
}
.select_clack-day{
    position: relative;
    max-width: 200px;
}
.select_clack-day select{
    position: relative;
    z-index: 0;
    padding: 11px 45px 11px 15px;
    background-color: black;
    border:1px solid white;
    border-radius: 26px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    line-height: 1.5rem;
}
.select_clack-day select::-ms-expand {
    display: none;
}
.select_clack-day i{
    position: absolute;
    z-index: 1;
	right: 20px;
	top: calc(50% - 10px);
	width: 10px;
	height: 10px;
	display: block;
	border-left:3px solid #fff;
	border-bottom:3px solid #fff;
	transform: rotate(-45deg); /* Giramos el cuadrado */
	transition: all 0.25s ease;
}
.categories-filter{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    list-style: none;
}

.categories-filter--item button{
    background-color: transparent;
    color: #fff;
    outline: none !important;
    border: 0 none !important;
    white-space: nowrap;
    font-size: 0.9rem;
    cursor: pointer;
}
.categories-filter--item button.active, .categories-filter--item button:hover{
    color: #FF2C83;
}
.searchbox-clack{
    display: flex;
    background-color: rgba(255,255,255,.2);
    border-radius: 22px;
    width: 208px;
    padding: 10px 15px;
    box-sizing: border-box;
    transition:width 0.3s ease 0s;
}
.searchbox-clack input[name="searchInput"]{
    background-color: transparent !important;
    border: 0 none;
    outline: none;
    color: #fff;
    width: 100%;
    padding: 0 10px;
}

.searchbox-clack.searching{
    width: 300px;
}
.searchbox-clack button{
    background-color: transparent;
    border: 0 none;
    outline: none;
    cursor: pointer;
}
.searchbox-clack button:hover{
    opacity: .6;
    cursor: pointer;
}
.hourbar-container{
    display: grid;
    grid-template-columns: auto 5fr auto;
    padding-left: 10%;
    padding-top: 24px;
    padding-bottom: 18px;
    padding-right: 20px;
    gap: 2rem ;
    width: 100%;
    box-sizing: border-box;
}
.arrow-hour{
    background-color: transparent;
    outline: none;
    color: #fff;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 0 none;
}
.arrow-hour i{
    position: relative;
    z-index: 1;
	top: calc(50% - 8px);
	width: 10px;
	height: 10px;
	display: block;
	border-left:3px solid #fff;
	border-bottom:3px solid #fff;
	transition: all 0.25s ease;
}
.arrow-hour.disabled{
    opacity: .4;
    border: 0 none;
    outline: none;
}
.arrow-hour--prev i{
    transform: rotate(45deg);
}
.arrow-hour--next i{
    transform: rotate(225deg);
}
.navigation-hour{
    width: 100%;
    overflow-x: hidden;
    position:relative;
    height: 30px;
}
.navigation-hour ul{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: #fff;
    position: absolute;
    transition: left 0.3s ease 0s;
    margin: 0;
    padding: 0;
}
.navigation-hour ul li{
    width: 179px;
    display: inline-block;
}
@media (max-width:768px){
    .hourbar-container{
        padding-left: 22%;
    }
    .filters-clack {
        grid-template-columns: 2fr 1fr;
        padding-left: 0;
        padding-bottom: 18px;
    }
    .searchbox-clack, .searchbox-clack.searching {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
        width: 100%;
    }
    .select_clack-day select{
        border: 0 none;
        border-radius: 0;
        font-size: 14px;
        padding: 5px 10px;
        outline: unset;
    }
    .select_clack-day i{
        right: 10px;
    }
    .filters-clack .select_clack-day:first-child select{
        color: #FF2C83;
    }
    .select_clack-day i{
        width: 7px;
        height: 7px;
        top: calc(50% - 6px);
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    .filters-clack .select_clack-day:first-child i{
        border-color: #FF2C83;
    }
}