.schelude-mobile-row{
    padding: 19px;
    color: #fff;
    font-weight: 300;
    border-bottom: 1px solid #575757;
}
.schelude-mobile-row .schelud-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
}
.schelude-mobile-row ul{
    padding: 0;
    margin: 0;
    padding: 0;
    list-style: none;
}
.schelude-mobile-row ul.schelude-mobile-container-ul{
    max-height: 67px;
    overflow: hidden;
    transition: all 0.25s ease;
}
.schelude-mobile-row.active ul.schelude-mobile-container-ul{
    max-height: unset;
}
.schelude-mobile-row ul li{
    display: grid;
    grid-template-columns: 48px auto;
    grid-gap: 11px;
    font-size: 16px;
}
.schelude-mobile-row ul.schelude-mobile-container-ul li.hoy-true:first-child{
    font-weight: 700;
}
.schelude-mobile-row .schelud-header img{
    filter: grayscale(100%);
    transition: filter 0.2s ease 0s;
}
.schelude-mobile-row.active  .schelud-header img{
    filter: none;
}
.schelude-mobile-row ul.schelude-mobile-container-ul li span{
    white-space: nowrap;
    overflow: hidden;
}
.schelude-mobile-row .btn-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0 0;
}
.schelude-mobile-row .btn-holder i{
    width: 7px;
    height: 7px;
    top: calc(50% - 6px);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    transition: all 0.25s ease;
}