@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,700;0,900;1,700;1,900&display=swap');
*{
  box-sizing:border-box;
}
body{
  background-color: #000;
}
#grid-clack ::-webkit-scrollbar {
  width: 14px;
}
#grid-clack ::-webkit-scrollbar-thumb {
  background: #efefef;
  border-radius: 20px; 
}
#grid-clack ::-webkit-scrollbar-track {
  background: rgba(51, 51, 51, 0.5);
  border-radius: 20px; 
}
.loading-holder{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}